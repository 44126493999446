function passwordRule(error) {
  var errorMessage = error
    ? error
    : "La password deve essere di 6-25 caratteri e contenere almeno 1 carattere minuscolo, 1 carattere maiuscolo, 1 numero ed 1 carattere speciale";
  return function(v) {
    return (
      !v ||
      /^(?=.*[a-z])(?=.*[A-Z])(?=.*[0-9])(?=.*[@#$%^&+*!=]).{8,25}$/.test(v) ||
      errorMessage
    );
  };
}

function isFidelityCard(error) {
  var errorMessage = error ? error : "Non è un numero di Aliperme Card valido.";
  return function(v) {
    return !v || /^[0-9]{13}$/.test(v) || errorMessage;
  };
}

function isLotteryCode(error) {
  var errorMessage = error ? error : "Il codice deve essere di otto caratteri.";
  return function(v) {
    return !v || /^[a-zA-Z0-9]{8}$/.test(v) || errorMessage;
  };
}

export { passwordRule, isFidelityCard, isLotteryCode };
