<template>
  <div class="user-email-address-container mb-5">
    <v-form v-model="isValid" ref="form" @submit.prevent>
      <v-row>
        <!--EMAIL-->
        <v-col cols="12" md="5">
          <label v-html="`${$t('profile.contacts.email')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.email"
            :rules="emailRules"
            :placeholder="`${$t('profile.contacts.email')}`"
            required
            outlined
            dense
            :disabled="isFieldDisabled"
          ></v-text-field>
        </v-col>
        <!--CONFIRM LA MAIL-->
        <v-col cols="12" md="5">
          <label v-html="`${$t('profile.contacts.emailConfirm')} *`" />
          <v-text-field
            class="mt-2"
            v-model="userData.confirmEmail"
            :rules="emailConfirmRules"
            :placeholder="`${$t('profile.contacts.emailConfirm')}`"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
        <!--PASSWORD-->
        <v-col cols="12" md="5">
          <label v-html="`${$t('profile.password')} *`" />
          <v-text-field
            class="mt-2"
            color="secondary"
            v-model="userData.password"
            :placeholder="`${$t('profile.password')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
          <legend class="text-caption">
            {{ $t("profile.passwordInfo") }}
          </legend>
        </v-col>
        <!--CONFIRM PASSWORD-->
        <v-col cols="12" md="5">
          <label v-html="`${$t('profile.passwordConfirm')} *`" />
          <v-text-field
            class="mt-2"
            color="secondary"
            v-model="passwordConfirm"
            :placeholder="`${$t('profile.passwordConfirm')}`"
            :append-icon="showPassword ? '$eye' : '$eyeOff'"
            :type="showPassword ? 'text' : 'password'"
            :rules="passwordConfirmRules"
            @click:append="toggleShowPassword"
            required
            outlined
            dense
          ></v-text-field>
        </v-col>
      </v-row>
      <v-row justify="space-between">
        <RegistrationModuleDisclaimer
          class="px-3"
          v-if="disclaimerList"
          :disclaimerList="disclaimerList"
          :options="disclaimerOptions"
          @disclaimerChanged="disclaimerChanged"
        />
        <v-col cols="12" sm="5">
          <v-btn
            depressed
            block
            x-large
            outlined
            v-html="$t('register.card.step-0.btnBack')"
            @click="back"
            class="mb-5 mb-sm-0"
          />
        </v-col>
        <v-col cols="12" sm="5">
          <v-btn
            depressed
            color="primary"
            block
            x-large
            :disabled="disabledRegistration"
            v-html="$t('register.card.step-0.btnConfirm')"
            @click="submit"
        /></v-col>
      </v-row>
    </v-form>
  </div>
</template>

<script>
import { passwordRule } from "@/customValidationRules";
import { isEmail, requiredValue } from "~/validator/validationRules";
import UserService from "~/service/userService";
import RegistrationModuleDisclaimer from "@/commons/components/disclaimer/RegistrationModuleDisclaimer.vue";

export default {
  name: "UserEmailAddress",
  props: {
    isFieldDisabled: { type: Boolean, default: false },
    userData: { type: Object, required: false },
    supportData: { type: Object, required: false },
    isProfileForm: { type: Boolean, default: false },
    showButtons: { type: Boolean, default: true }
  },
  components: {
    RegistrationModuleDisclaimer
  },
  data() {
    return {
      isValid: false,
      showPassword: false,
      passwordConfirm: null,
      disclaimerList: null,
      response: {},
      disclaimerOptions: {
        color: "primary"
      },
      passwordRules: [requiredValue("Digitare la password"), passwordRule()],
      passwordConfirmRules: [
        v => !!v || "Confermare la password",
        v => v === this.userData.password || "Le password non coincidono"
      ],
      emailRules: [requiredValue("Digitare l'e-mail"), isEmail()],
      emailConfirmRules: [
        v => !!v || "Confermare l'e-mail",
        v => v === this.userData.email || "Le e-mail non coincidono"
      ],
      requiredRules: requiredValue
    };
  },
  computed: {
    disabledRegistration() {
      return !this.isValid;
    }
  },
  methods: {
    back() {
      this.$emit("back");
    },
    submit() {
      this.$emit("submit", true, true);
    },
    async loadDisclaimer() {
      let storeId = 5;
      let registrationModuleTypeId = 2;
      let res = await UserService.getRegistrationDisclaimerList(
        registrationModuleTypeId,
        storeId
      );
      const disclaimers = res.registrationDisclaimers;
      this.disclaimerList = disclaimers.sort((a, b) => a.priority - b.priority);
      console.log("getDisclaimer", res);
    },
    disclaimerChanged(registrationModules) {
      this.registrationModules = registrationModules;
      this.userData.registrationModules = this.registrationModules;
    },
    toggleShowPassword(e) {
      e.preventDefault();
      this.showPassword = !this.showPassword;
    }
  },

  mounted() {
    this.loadDisclaimer();
  }
};
</script>
